import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
  CircularProgress,
  InputAdornment,
  IconButton,
  FormHelperText,
} from '@material-ui/core';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

import { HTTPService } from 'core/services';
import SuccessfulSignup from 'features/Signup/SuccessfulSignup';

import createPasswordAction from './actions';
import PasswordPolicies from './PasswordPolicies';

const CreatePassword = () => {
  const dispatch = useDispatch();
  const source = HTTPService.generateSource();
  const isPasswordCreationInProgress = useSelector((state) => state.createPassword.isLoading);
  const passwordCreationError = useSelector((
    state,
  ) => state.createPassword.error);
  const { email } = useSelector((state) => state.verifyEmail.data);
  const [values, setValues] = useState({
    password: '',
    showPassword: false,
  });
  const [enableAccountState, setEnableAccountState] = useState({
    creationPassword: true,
    successfulSignup: false,
  });
  const [invalidPasswordError, setInvalidPasswordError] = useState(!!passwordCreationError);

  const changePassword = (event) => {
    event.preventDefault();

    setValues({ ...values, password: event.target.value });
    setInvalidPasswordError(false);
  };

  const createPassword = async (e) => {
    e.preventDefault();

    const data = {
      email,
      password: values.password,
    };

    // eslint-disable-next-line no-useless-catch
    try {
      await dispatch(createPasswordAction(source, data));

      setEnableAccountState({
        creationPassword: false,
        successfulSignup: true,
      });
    } catch (error) {
      setInvalidPasswordError(error);
      throw error;
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const showPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const getCreationPassword = () => (email ? (
    <div className="create-password">
      <div className="create-password__continents" />
      <div className="create-password__lines" />
      <section className={`create-password__wrap fade-in-animated ${isPasswordCreationInProgress ? 'readonly' : ''}`}>
        <Typography variant="h1" component="h1" className="create-password__title">Create your password</Typography>
        <form className="create-password__form" onSubmit={createPassword}>
          <div className="create-password__row">
            <FormControl fullWidth variant="outlined" error={invalidPasswordError}>
              <InputLabel htmlFor="password" className="MuiInputLabel_large">Password</InputLabel>
              <OutlinedInput
                className="MuiOutlinedInput_large"
                id="password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={changePassword}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={showPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword
                        ? <AiOutlineEye size={22} />
                        : <AiOutlineEyeInvisible size={22} />}
                    </IconButton>
                  </InputAdornment>
                        )}
                label="Password"
                error={invalidPasswordError}
                data-testid="password"
              />
              { invalidPasswordError ? (
                <FormHelperText className="create-password__error">
                  <Typography variant="body2" component="span">
                    {passwordCreationError.response.data.detail}
                  </Typography>
                </FormHelperText>
              ) : null }
            </FormControl>
          </div>
          <PasswordPolicies />
          <div className="create-password__row">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className="create-password__btn-confirm"
              disabled={!values.password}
              type="submit"
              data-testid="confirmButton"
            >
              {isPasswordCreationInProgress ? <CircularProgress color="white" size={25} /> : 'Confirm'}
            </Button>
          </div>
        </form>
      </section>
    </div>
  ) : '');

  return (
    <>
      {enableAccountState.creationPassword && getCreationPassword()}
      {enableAccountState.successfulSignup && <SuccessfulSignup />}
    </>
  );
};

export default CreatePassword;
