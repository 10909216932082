import {
  VERIFY_ACCOUNT_REQUEST, VERIFY_ACCOUNT_SUCCESS, VERIFY_ACCOUNT_FAILURE,
} from './actionTypes';

export const verifyAccountRequest = () => (
  { type: VERIFY_ACCOUNT_REQUEST });
export const verifyAccountSuccess = (data) => (
  { type: VERIFY_ACCOUNT_SUCCESS, data });
export const verifyAccountFailure = (error) => (
  { type: VERIFY_ACCOUNT_FAILURE, error });
