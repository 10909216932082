import {
  GET_PASSWORD_POLICIES_REQUEST, GET_PASSWORD_POLICIES_SUCCESS, GET_PASSWORD_POLICIES_FAILURE,
} from './actionTypes';

export const getPasswordPoliciesRequest = () => (
  { type: GET_PASSWORD_POLICIES_REQUEST });
export const getPasswordPoliciesSuccess = (data) => (
  { type: GET_PASSWORD_POLICIES_SUCCESS, data });
export const getPasswordPoliciesFailure = (error) => (
  { type: GET_PASSWORD_POLICIES_FAILURE, error });
