import {
  CREATE_PASSWORD_REQUEST, CREATE_PASSWORD_SUCCESS, CREATE_PASSWORD_FAILURE,
} from './actionTypes';

export const createPasswordRequest = () => (
  { type: CREATE_PASSWORD_REQUEST });
export const createPasswordSuccess = (data) => (
  { type: CREATE_PASSWORD_SUCCESS, data });
export const createPasswordFailure = (error) => (
  { type: CREATE_PASSWORD_FAILURE, error });
