import {
  CREATE_ACCOUNT_REQUEST, CREATE_ACCOUNT_SUCCESS, CREATE_ACCOUNT_FAILURE,
} from './actionTypes';

export const createAccountRequest = () => (
  { type: CREATE_ACCOUNT_REQUEST });
export const createAccountSuccess = (data) => (
  { type: CREATE_ACCOUNT_SUCCESS, data });
export const createAccountFailure = (error) => (
  { type: CREATE_ACCOUNT_FAILURE, error });
