import {
  CHECK_USER_PROVIDER_REQUEST,
  CHECK_USER_PROVIDER_SUCCESS,
  CHECK_USER_PROVIDER_FAILURE,
} from './actionTypes';

export const checkUserProviderRequest = () => (
  { type: CHECK_USER_PROVIDER_REQUEST });
export const checkUserProviderSuccess = (data) => (
  { type: CHECK_USER_PROVIDER_SUCCESS, data });
export const checkUserProviderFailure = (error) => (
  { type: CHECK_USER_PROVIDER_FAILURE, error });
