import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from 'core/rootReducer';

const isDevelopment = process.env.NODE_ENV === 'development';
const loggerMiddleware = createLogger();
const middleware = isDevelopment ? applyMiddleware(
  thunkMiddleware,
  loggerMiddleware,
) : applyMiddleware(thunkMiddleware);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancer(middleware),
);

export default store;
