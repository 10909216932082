import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import pathes from 'core/configs/pathesConfig';
import ValidatedEmailFormControl from 'shared/ValidatedEmailFormControl/';
import { HTTPService } from 'core/services';
import { isEmptyObject, useQuery } from 'core/utilities';

import connectedPlatformLogo from 'assets/images/connected-platform-logo.svg';

import { checkUserProviderAction } from './actions';

import './Signin.scss';

const authSkipCookieName = 'ncp_auth_skip';

const Signin = () => {
  const dispatch = useDispatch();
  const source = HTTPService.generateSource();
  const isCheckUserProviderInProgress = useSelector((state) => state.checkUserProvider.isLoading);
  const keycloakUrl = useSelector((state) => state.appVariables.data.keycloakUrl);
  const domain = useSelector((state) => state.appVariables.data.domain);
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [serverError, setServerError] = useState('');
  const [isDefaultServerError, setIsDefaultServerError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies([authSkipCookieName]);
  const authSkipCookie = cookies && cookies[authSkipCookieName];
  const isDisabledProceedBtn = !isValidEmail || !email.length;
  const queryParams = useQuery();
  const oauthQueryParams = {};

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setServerError('');
    setIsDefaultServerError(false);
  };

  const prepareQueryParamsDataToSend = () => {
    queryParams.forEach((value, key) => {
      oauthQueryParams[key] = value;
    });
  };

  prepareQueryParamsDataToSend();

  const checkUserProvider = async (e) => {
    e.preventDefault();

    const oauthQueryParamsData = !isEmptyObject(oauthQueryParams) ? { oauth_query: oauthQueryParams } : '';

    const data = {
      email,
      ...oauthQueryParamsData,
    };

    try {
      const redirectData = await dispatch(checkUserProviderAction(source, data));

      window.location.replace(redirectData.login_url);
    } catch (error) {
      const isStringErrorData = typeof (error.response.data.detail) === 'string';

      if (isStringErrorData) {
        setServerError(error.response.data.detail);
      } else {
        setIsDefaultServerError(true);
      }

      throw error;
    }
  };

  const defaultServerErrorMsg = (
    <Typography variant="body2" component="span" className="signin__server-error-msg">
      Sorry, we can’t authorize you. Please try again later.
    </Typography>
  );

  const serverErrorMsg = (
    <Typography variant="body2" component="span" className="signin__server-error-msg">
      Sorry, we can’t authorize you. Additional information:
      {' '}
      {serverError}
    </Typography>
  );

  const skipSignin = () => {
    const redirectUrl = `${keycloakUrl}${window.location.search}`;

    removeCookie(authSkipCookieName, { path: '/', domain });

    window.location.replace(redirectUrl);

    return null;
  };

  return authSkipCookie
    ? skipSignin()
    : (
      <div className={`signin__layout ${isCheckUserProviderInProgress ? 'readonly' : ''}`}>
        <div className="signin" data-testid="signin">
          <img src={connectedPlatformLogo} width="160" height="60" alt="Connected Platform logo" />
          <form className="signin__form" onSubmit={checkUserProvider}>
            <Typography variant="body1" component="p" className="signin__form-sub-title">Sign In with your email to the</Typography>
            <Typography variant="h2" component="h2" className="signin__form-title">Nextgen Connected Platform</Typography>
            <ValidatedEmailFormControl
              email={email}
              handleEmailChange={handleEmailChange}
              setIsValidEmail={setIsValidEmail}
              className="signin__form-validated-email"
              isAutoFocused
            />
            <Button
              fullWidth
              disabled={isDisabledProceedBtn}
              variant="contained"
              color="primary"
              className="signin__form-btn"
              type="submit"
            >
              {isCheckUserProviderInProgress ? <CircularProgress color="white" size={25} /> : 'Proceed'}
            </Button>
          </form>
          <div className="signin__server-error">
            { serverError && serverErrorMsg}
            { isDefaultServerError && defaultServerErrorMsg}
          </div>
          <Link to={pathes.signup} className="signin__link">Create an account</Link>
          <Typography variant="body2" component="p" className="signin__issues bold">
            Having issues? Please contact
            {' '}
            <a href="mailto:support@nextgenclearing.com" className="signin__support-email">support@nextgenclearing.com</a>
          </Typography>
        </div>
      </div>
    );
};

export default Signin;
