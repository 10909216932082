import { combineReducers } from 'redux';

import appVariablesReducer from 'core/state/AppVariables/reducer';
import getNotFoundFromServerReducer from 'features/PageNotFound/reducer';
import validateInviteReducer from 'features/Signup/ValidateInvite/reducer';
import verifyAccountReducer from 'features/Signup/VerifyAccount/reducer';
import createAccountReducer from 'features/Signup/CreateAccount/reducer';
import verifyEmailReducer from 'features/VerifyEmail/reducer';
import sendEmailReducer from 'features/SendEmail/reducer';
import checkUserProviderReducer from 'features/Signin/reducer';
import createPasswordReducer from 'features/CreatePassword/reducer';
import getPasswordPoliciesReducer from 'features/CreatePassword/PasswordPolicies/reducer';

const rootReducer = combineReducers({
  appVariables: appVariablesReducer,
  getNotFoundFromServer: getNotFoundFromServerReducer,
  verifyAccount: verifyAccountReducer,
  createAccount: createAccountReducer,
  validateInvite: validateInviteReducer,
  verifyEmail: verifyEmailReducer,
  sendEmail: sendEmailReducer,
  checkUserProvider: checkUserProviderReducer,
  createPassword: createPasswordReducer,
  getPasswordPolicies: getPasswordPoliciesReducer,
});

export default rootReducer;
