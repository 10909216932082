import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';

import { useMountTransition } from 'core/utilities';
import { HTTPService } from 'core/services';
import pathes from 'core/configs/pathesConfig';
import VerifyAccount from 'features/Signup/VerifyAccount';
import CreateAccount from 'features/Signup/CreateAccount';
import validateInviteAction from 'features/Signup/ValidateInvite/actions';
import PageNotFound from 'features/PageNotFound';
import getNotFoundFromServerAction from 'features/PageNotFound/actions';
import AccountActivationInfo from 'features/Signup/AccountActivationInfo';

const classNameForFadeAnimation = 'fade-in-animated';
const unmountDelay = '500';
const unprocessableEntityStatus = 422;

const Signup = () => {
  const dispatch = useDispatch();
  const source = HTTPService.generateSource();
  const [accountState, setAccountState] = useState({
    verification: true,
    creation: false,
    activationInfo: false,
  });
  const [fadeAnimationState, setFadeAnimationState] = useState(false);
  const inviteKey = useLocation().pathname.replace(pathes.signup, '').replace('/', '');
  const isInviteValidationInProgress = useSelector((state) => state.validateInvite.isLoading);
  const validInviteKey = useSelector((state) => state.validateInvite.data.key);
  const hasTransitionedIn = {
    verification: useMountTransition(accountState.verification, unmountDelay),
    creation: useMountTransition(accountState.creation, unmountDelay),
  };

  const getClassNameForFadeAnimation = () => (!fadeAnimationState ? classNameForFadeAnimation : '');

  // eslint-disable-next-line no-shadow
  const getClassNamesForSlideAnimation = (hasTransitionedIn1, mountState) => `${hasTransitionedIn1 && 'in'} ${mountState && 'visible'}`;

  const getSignupByValidInvite = () => (
    <>
      { !accountState.activationInfo && (
      <>
        <div className="signup__continents" />
        <div className="signup__lines" />
      </>
      )}
      { (hasTransitionedIn.verification || accountState.verification)
          && (
          <VerifyAccount
            setAccountState={setAccountState}
            className={`${getClassNameForFadeAnimation()} shifted-to-the-left ${getClassNamesForSlideAnimation(hasTransitionedIn.verification, accountState.verification)}`}
            setFadeAnimationState={setFadeAnimationState}
          />
          )}
      { (hasTransitionedIn.creation || accountState.creation)
          && (
          <CreateAccount
            setAccountState={setAccountState}
            className={`shifted-to-the-right ${getClassNamesForSlideAnimation(hasTransitionedIn.creation, accountState.creation)}`}
          />
          )}
      { accountState.activationInfo && <AccountActivationInfo /> }
    </>
  );

  // eslint-disable-next-line no-nested-ternary
  const getSignupByInvite = () => (isInviteValidationInProgress
    ? <LinearProgress color="primary" data-testid="signup__preloader" />
    : validInviteKey ? getSignupByValidInvite() : '');

  useEffect(async () => {
    if (inviteKey) {
      try {
        await dispatch(validateInviteAction(source, inviteKey));
      } catch (error) {
        const statusCode = error.response.status;

        // later need to be replaced with a page "Invalid invite key"
        if (statusCode === unprocessableEntityStatus) {
          dispatch(getNotFoundFromServerAction());
        }
      }
    }
  }, []);

  return (
    <div className="base-layout__content">
      {inviteKey ? getSignupByInvite() : <PageNotFound />}
    </div>
  );
};

export default Signup;
