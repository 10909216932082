import {
  SEND_EMAIL_FAILURE, SEND_EMAIL_REQUEST, SEND_EMAIL_SUCCESS,
} from './actionTypes';

const initialState = { error: false, data: { } };

const sendEmailReducer = (state = initialState, { type, data, error }) => {
  switch (type) {
    case SEND_EMAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        data,
        isLoading: false,
      };
    case SEND_EMAIL_FAILURE:
      return {
        ...state,
        error,
        isLoading: false,
      };
    default:
      return {
        ...state,
        isLoading: false,
      };
  }
};

export default sendEmailReducer;
