import {
  VERIFY_ACCOUNT_FAILURE, VERIFY_ACCOUNT_REQUEST, VERIFY_ACCOUNT_SUCCESS,
} from './actionTypes';

const initialState = { error: false, data: {} };

const verifyAccountReducer = (state = initialState, { type, data, error }) => {
  switch (type) {
    case VERIFY_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case VERIFY_ACCOUNT_SUCCESS:
      return {
        ...state,
        data,
        isLoading: false,
      };
    case VERIFY_ACCOUNT_FAILURE:
      return {
        ...state,
        error,
        isLoading: false,
      };
    default:
      return {
        ...state,
        isLoading: false,
      };
  }
};

export default verifyAccountReducer;
