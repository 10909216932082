import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Redirect, Route, Switch,
} from 'react-router-dom';

import pathes from 'core/configs/pathesConfig';
import Header from 'features/Header';
import Signup from 'features/Signup';
import Signin from 'features/Signin';
import PageNotFound from 'features/PageNotFound';
import VerifyEmail from 'features/VerifyEmail';
import CreatePassword from 'features/CreatePassword';

const BaseLayout = ({ history }) => {
  const isNotFoundFromServer = useSelector(((state) => state.getNotFoundFromServer.notFound));
  return (
    <div className="base-layout">
      <Header />
      <Switch>
        <Route path={pathes.signup}>
          <Signup />
          {isNotFoundFromServer ? <PageNotFound /> : ''}
        </Route>
        <Route path={pathes.signin}>
          <Signin />
        </Route>
        <Route path={pathes.verifyEmail}>
          <VerifyEmail history={history} />
        </Route>
        <Route path={pathes.createPassword}>
          <CreatePassword />
        </Route>
        <Redirect to={pathes.signin} />
      </Switch>
    </div>
  );
};

BaseLayout.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default BaseLayout;
