import {
  VERIFY_EMAIL_FAILURE, VERIFY_EMAIL_REQUEST, VERIFY_EMAIL_SUCCESS,
} from './actionTypes';

const initialState = { error: false, data: { email: '' }, isLoading: false };

const verifyEmailReducer = (state = initialState, { type, data, error }) => {
  switch (type) {
    case VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        data,
        isLoading: false,
      };
    case VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        error,
        isLoading: false,
      };
    default:
      return {
        ...state,
        isLoading: false,
      };
  }
};

export default verifyEmailReducer;
