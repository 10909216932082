import { HTTPService } from 'core/services';
import verifyAccount from './api.service';
import {
  verifyAccountFailure,
  verifyAccountRequest,
  verifyAccountSuccess,
} from './actionsCreators';

export const verifyAccountAction = (source, userData) => async (dispatch) => {
  try {
    const cancelToken = HTTPService.getCancelToken(source);

    dispatch(verifyAccountRequest());

    const data = await verifyAccount(userData, cancelToken);

    dispatch(verifyAccountSuccess(data));

    return data;
  } catch (error) {
    dispatch(verifyAccountFailure(error));
    throw error;
  }
};

export default verifyAccountAction;
