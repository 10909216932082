import {
  CREATE_PASSWORD_FAILURE, CREATE_PASSWORD_REQUEST, CREATE_PASSWORD_SUCCESS,
} from './actionTypes';

const initialState = { error: false, data: {} };

const createPasswordReducer = (state = initialState, { type, data, error }) => {
  switch (type) {
    case CREATE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_PASSWORD_SUCCESS:
      return {
        ...state,
        data,
        isLoading: false,
      };
    case CREATE_PASSWORD_FAILURE:
      return {
        ...state,
        error,
        isLoading: false,
      };
    default:
      return {
        ...state,
        isLoading: false,
      };
  }
};

export default createPasswordReducer;
