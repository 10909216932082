import { HTTPService, ConfigSettingsService } from 'core/services';
import {
  getAppVariablesFailure,
  getAppVariablesRequest,
  getAppVariablesSuccess,
} from './actionsCreators';

export const getAppVariablesAction = (source) => async (dispatch) => {
  try {
    const cancelToken = HTTPService.getCancelToken(source);

    dispatch(getAppVariablesRequest(cancelToken));

    const { data } = await ConfigSettingsService.getAppVariables();

    dispatch(getAppVariablesSuccess(data));

    return data;
  } catch (error) {
    dispatch(getAppVariablesFailure(error));
    throw error;
  }
};

export default getAppVariablesAction;
