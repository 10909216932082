import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  Typography, FormControl, InputLabel,
  OutlinedInput, CircularProgress, Button,
  Checkbox, FormControlLabel, Link,
} from '@material-ui/core';

import { HTTPService } from 'core/services';
import { verifyAccountAction } from './actions';

const privacyPolicyUrl = 'http://nextgenclearing.com/privacy-policy';
const termsAndConditionsUrl = 'http://nextgenclearing.com/terms-and-conditions';

const VerifyAccount = ({ setAccountState, className, setFadeAnimationState }) => {
  const dispatch = useDispatch();
  const source = HTTPService.generateSource();
  const description = useSelector((state) => state.validateInvite.data.description);
  const emailFromValidation = useSelector((state) => state.validateInvite.data.email);
  const firstNameFromServer = useSelector((state) => state.validateInvite.data.first_name);
  const lastNameFromServer = useSelector((state) => state.validateInvite.data.last_name);
  const savedFirstName = useSelector((state) => state.verifyAccount.data.first_name);
  const savedLastName = useSelector((state) => state.verifyAccount.data.last_name);
  const inviteKey = useSelector((state) => state.validateInvite.data.key);
  const [email, setEmail] = useState(emailFromValidation);
  const [firstName, setFirstName] = useState(savedFirstName || firstNameFromServer);
  const [lastName, setLastName] = useState(savedLastName || lastNameFromServer);
  const [agreementToTheTerms, setAgreementToTheTerms] = useState(false);
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);
  const isAccountVerificationInProgress = useSelector((state) => state.verifyAccount.isLoading);

  const checkAndSetConfirmButtonDisabled = (firstNameVal, lastNameVal, agreementToTheTermsVal) => {
    const isDisabled = !firstNameVal || !lastNameVal || !agreementToTheTermsVal;

    setConfirmButtonDisabled(isDisabled);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    const newFirstNameVal = e.target.value;

    setFirstName(newFirstNameVal);
    checkAndSetConfirmButtonDisabled(newFirstNameVal, lastName, agreementToTheTerms);
  };

  const handleLastNameChange = (e) => {
    const newLastNameVal = e.target.value;

    setLastName(newLastNameVal);
    checkAndSetConfirmButtonDisabled(firstName, newLastNameVal, agreementToTheTerms);
  };

  const handleAgreementToTheTerms = (e) => {
    const newAgreementToTheTermsVal = e.target.checked;

    checkAndSetConfirmButtonDisabled(firstName, lastName, newAgreementToTheTermsVal);
    setAgreementToTheTerms(newAgreementToTheTermsVal);
  };

  const verifyAccount = async (e) => {
    e.preventDefault();

    setFadeAnimationState((state) => ({
      ...state,
      startBlockState: true,
    }));

    const data = {
      key: inviteKey,
      first_name: firstName,
      last_name: lastName,
    };

    try {
      await dispatch(verifyAccountAction(source, data));

      setAccountState((state) => ({
        ...state,
        verification: false,
        creation: true,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    checkAndSetConfirmButtonDisabled(firstName, lastName);
  }, []);

  return (
    <section className={`create-account__wrap verify-account__wrap signup__block ${className} ${isAccountVerificationInProgress ? 'readonly' : ''}`}>
      {description && (
      <Typography variant="body1" component="span" className="create-account__text">
        {description}
        , to continue
      </Typography>
      ) }
      <Typography variant="h1" component="h1" className="create-account__title">Create your account</Typography>
      <form className="create-account__form" onSubmit={verifyAccount}>
        <div className="create-account__row">
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="email" className="MuiInputLabel_large" disabled>Email</InputLabel>
            <OutlinedInput
              id="email"
              value={email}
              type="text"
              onChange={handleEmailChange}
              disabled
              labelWidth={60}
              className="MuiOutlinedInput_large"
            />
          </FormControl>
        </div>
        <div className="create-account__row">
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="firstName" className="MuiInputLabel_large">First name</InputLabel>
            <OutlinedInput
              id="firstName"
              value={firstName}
              type="text"
              onChange={handleFirstNameChange}
              labelWidth={90}
              className="MuiOutlinedInput_large"
              data-testid="firstName"
              autoFocus
            />
          </FormControl>
        </div>
        <div className="create-account__row">
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="lastName" className="MuiInputLabel_large">Last name</InputLabel>
            <OutlinedInput
              id="lastName"
              value={lastName}
              type="text"
              onChange={handleLastNameChange}
              labelWidth={90}
              className="MuiOutlinedInput_large"
              data-testid="lastName"
            />
          </FormControl>
        </div>
        <div className="create-account__row-min">
          <FormControlLabel
            control={(
              <Checkbox
                checked={agreementToTheTerms}
                onChange={handleAgreementToTheTerms}
                name="agreementToTheTerms"
                color="primary"
                icon={<span className="MuiCheckbox-icon" />}
                data-testid="agreementToTheTerms"
              />
            )}
            label={(
              <Typography variant="body2" component="span" className="verify-account__checkbox-text">
                I agree with
                {' '}
                <Link href={privacyPolicyUrl} target="_blank" className="verify-account__link">Privacy Policy</Link>
                {' '}
                and
                {' '}
                <Link href={termsAndConditionsUrl} target="_blank" className="verify-account__link">Terms and Conditions</Link>
              </Typography>
)}
          />
        </div>
        <div className="create-account__row-min">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className="create-account__btn-confirm"
            disabled={confirmButtonDisabled}
            type="submit"
            data-testid="confirmButton"
          >
            {isAccountVerificationInProgress ? <CircularProgress color="white" size={25} /> : 'Confirm'}
          </Button>
        </div>
      </form>
    </section>
  );
};

VerifyAccount.propTypes = {
  setAccountState: PropTypes.func,
  className: PropTypes.string,
  setFadeAnimationState: PropTypes.func,
};

VerifyAccount.defaultProps = {
  setAccountState: () => {},
  className: '',
  setFadeAnimationState: () => {},
};

export default VerifyAccount;
