import {
  CREATE_ACCOUNT_FAILURE, CREATE_ACCOUNT_REQUEST, CREATE_ACCOUNT_SUCCESS,
} from './actionTypes';

const initialState = { error: false, data: { email: '' } };

const createAccountReducer = (state = initialState, { type, data, error }) => {
  switch (type) {
    case CREATE_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        data,
        isLoading: false,
      };
    case CREATE_ACCOUNT_FAILURE:
      return {
        ...state,
        error,
        isLoading: false,
      };
    default:
      return {
        ...state,
        isLoading: false,
      };
  }
};

export default createAccountReducer;
