import { HTTPService } from 'core/services';
import createPassword from './api.service';
import {
  createPasswordFailure,
  createPasswordRequest,
  createPasswordSuccess,
} from './actionsCreators';

export const createPasswordAction = (source, userData) => async (dispatch) => {
  try {
    const cancelToken = HTTPService.getCancelToken(source);

    dispatch(createPasswordRequest());

    const data = await createPassword(userData, cancelToken);

    dispatch(createPasswordSuccess(data));

    return data;
  } catch (error) {
    dispatch(createPasswordFailure(error));
    throw error;
  }
};

export default createPasswordAction;
