import {
  VERIFY_EMAIL_REQUEST, VERIFY_EMAIL_SUCCESS, VERIFY_EMAIL_FAILURE,
} from './actionTypes';

export const verifyEmailRequest = () => (
  { type: VERIFY_EMAIL_REQUEST });
export const verifyEmailSuccess = (data) => (
  { type: VERIFY_EMAIL_SUCCESS, data });
export const verifyEmailFailure = (error) => (
  { type: VERIFY_EMAIL_FAILURE, error });
