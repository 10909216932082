import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

import './ValidatedEmailFormControl.scss';

const ValidatedEmailFormControl = ({
  email, handleEmailChange, isDisabled, className, setIsValidEmail, isAutoFocused,
}) => {
  const emailRegex = /^\S+@\S+\.\S+$/;
  const [isFocused, setIsFocused] = useState(isAutoFocused);
  const isValidEmail = emailRegex.test(email);

  const changeEmail = (e) => {
    handleEmailChange(e);

    if (!isFocused) {
      setIsFocused(true);
    }
  };

  useEffect(() => {
    setIsValidEmail(isValidEmail);
  }, [email]);

  return (
    <FormControl error={!isValidEmail && !!email.length} fullWidth variant="outlined" className={`validated-email__form-control ${className}`}>
      <InputLabel htmlFor="email" className="MuiInputLabel_large">Email</InputLabel>
      <OutlinedInput
        id="email"
        type="text"
        labelWidth={60}
        className="MuiOutlinedInput_large"
        value={email}
        onChange={(e) => changeEmail(e)}
        onBlur={() => setIsFocused(false)}
        disabled={isDisabled}
        error={!isValidEmail && !!email.length}
        autoFocus={isAutoFocused}
        data-testid="validated-email-input"
      />
      <FormHelperText id="component-error-text">
        { !isValidEmail && !!email.length && !isFocused ? (
          <Typography variant="body2" component="span">Please, enter the correct email address</Typography>
        ) : null }
      </FormHelperText>
    </FormControl>
  );
};

ValidatedEmailFormControl.propTypes = {
  email: PropTypes.string.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  setIsValidEmail: PropTypes.func,
  isAutoFocused: PropTypes.bool,
};

ValidatedEmailFormControl.defaultProps = {
  isDisabled: false,
  className: '',
  setIsValidEmail: () => {},
  isAutoFocused: false,
};

export default ValidatedEmailFormControl;
