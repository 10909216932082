import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import {
  LinearProgress,
  Typography,
} from '@material-ui/core';

import { HTTPService } from 'core/services';
import pathes from 'core/configs/pathesConfig';
import emailImage from 'assets/images/activation-email.svg';
import PageNotFound from 'features/PageNotFound';
import { verifyEmailAction } from './actions';

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const source = HTTPService.generateSource();
  const token = useLocation().pathname.replace(pathes.verifyEmail, '').replace('/', '');
  const redirectToFromServer = new URLSearchParams(useLocation().search).get('redirect_to');
  const redirectTo = redirectToFromServer && redirectToFromServer.includes(window.origin)
    ? redirectToFromServer.replace(`${window.origin}`, '')
    : redirectToFromServer;
  const isEmailVerificationInProgress = useSelector((state) => state.verifyEmail.isLoading);
  const { email } = useSelector((state) => state.verifyEmail.data);

  const verifyEmail = () => {
    const data = {
      token,
    };

    return dispatch(verifyEmailAction(source, data));
  };

  useEffect(async () => {
    try {
      await verifyEmail();
    } catch (error) {
      console.error(error);
    }
  }, []);

  const checkToRedirect = () => (redirectTo && email ? <Redirect to={redirectTo} /> : '');

  const getVerifyEmailByToken = () => (isEmailVerificationInProgress
    ? (
      <>
        <LinearProgress color="primary" data-testid="verify-email__preloader" />
        <div className="verify-email__wrap">
          <img src={emailImage} width="124" height="124" alt="Email" />
          <Typography variant="h2" component="h2" className="verify-email__title">Checking your email</Typography>
          <Typography variant="body1" component="p" className="verify-email__text">
            Please wait
          </Typography>
        </div>
      </>
    )
    : checkToRedirect());

  return token ? getVerifyEmailByToken() : <PageNotFound />;
};

export default VerifyEmail;
