import axios from 'axios';
import setRequestErrorInterceptor from 'core/interceptors/requestInterceptors';

class HTTPService {
  static useAxiosInterceptors(apiUrl) {
    // eslint-disable-next-line no-param-reassign
    axios.defaults.baseURL = apiUrl;
    // eslint-disable-next-line no-param-reassign
    axios.defaults.withCredentials = true;
  }

  static generateSource() {
    return axios.CancelToken.source();
  }

  static getCancelToken(source) {
    return source.token;
  }

  static cancelRequest(source) {
    const message = 'Cancel request';

    return source.cancel(message);
  }

  static isCancel(error) {
    return axios.isCancel(error);
  }

  static setNotFoundError(dispatch) {
    axios.interceptors.response.use((res) => res.data, async (error) => {
      setRequestErrorInterceptor(error, dispatch);

      return Promise.reject(error);
    });
  }
}

export default HTTPService;
