import {
  CHECK_USER_PROVIDER_REQUEST,
  CHECK_USER_PROVIDER_SUCCESS,
  CHECK_USER_PROVIDER_FAILURE,
} from './actionTypes';

const checkUserProviderReducer = (state = { data: {}, isLoading: false }, {
  type, data, error,
}) => {
  switch (type) {
    case CHECK_USER_PROVIDER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CHECK_USER_PROVIDER_SUCCESS:
      return {
        ...state,
        data,
        isLoading: false,
      };
    case CHECK_USER_PROVIDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error,
      };
    default:
      return state;
  }
};

export default checkUserProviderReducer;
