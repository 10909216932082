import { HTTPService } from 'core/services';

import {
  checkUserProviderRequest,
  checkUserProviderSuccess,
  checkUserProviderFailure,
} from './actionsCreators';

import checkUserProvider from './api.service';

export const checkUserProviderAction = (source, userData) => async (dispatch) => {
  try {
    const cancelToken = HTTPService.getCancelToken(source);

    dispatch(checkUserProviderRequest());

    const data = await checkUserProvider(userData, cancelToken);

    dispatch(checkUserProviderSuccess(data));

    return data;
  } catch (error) {
    dispatch(checkUserProviderFailure(error));
    throw error;
  }
};

export default checkUserProviderAction;
