import { HTTPService } from 'core/services';
import getPasswordPolicies from './api.service';
import {
  getPasswordPoliciesFailure,
  getPasswordPoliciesRequest,
  getPasswordPoliciesSuccess,
} from './actionsCreators';

export const getPasswordPoliciesAction = (source) => async (dispatch) => {
  try {
    const cancelToken = HTTPService.getCancelToken(source);

    dispatch(getPasswordPoliciesRequest());

    const data = await getPasswordPolicies(cancelToken);

    dispatch(getPasswordPoliciesSuccess(data));

    return data;
  } catch (error) {
    dispatch(getPasswordPoliciesFailure(error));
    throw error;
  }
};

export default getPasswordPoliciesAction;
