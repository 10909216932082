import {
  SEND_EMAIL_REQUEST, SEND_EMAIL_SUCCESS, SEND_EMAIL_FAILURE,
} from './actionTypes';

export const sendEmailRequest = () => (
  { type: SEND_EMAIL_REQUEST });
export const sendEmailSuccess = (data) => (
  { type: SEND_EMAIL_SUCCESS, data });
export const sendEmailFailure = (error) => (
  { type: SEND_EMAIL_FAILURE, error });
