import React from 'react';
import getNotFoundFromServerAction from 'features/PageNotFound/actions';

const notFoundStatus = 404;

export const setRequestErrorInterceptor = (error, dispatch) => {
  if (!error.response) {
    return;
  }
  const statusCode = error.response.status;

  if (statusCode === notFoundStatus) {
    dispatch(getNotFoundFromServerAction());
  }
};

export default setRequestErrorInterceptor;
