import {
  VALIDATE_INVITE_REQUEST, VALIDATE_INVITE_SUCCESS, VALIDATE_INVITE_FAILURE,
} from './actionTypes';

export const validateInviteRequest = () => (
  { type: VALIDATE_INVITE_REQUEST });
export const validateInviteSuccess = (data) => (
  { type: VALIDATE_INVITE_SUCCESS, data });
export const validateInviteFailure = (error) => (
  { type: VALIDATE_INVITE_FAILURE, error });
