import { HTTPService } from 'core/services';
import createAccount from './api.service';
import {
  createAccountFailure,
  createAccountRequest,
  createAccountSuccess,
} from './actionsCreators';

export const createAccountAction = (source, userData) => async (dispatch) => {
  try {
    const cancelToken = HTTPService.getCancelToken(source);

    dispatch(createAccountRequest());

    const data = await createAccount(userData, cancelToken);

    dispatch(createAccountSuccess(data));

    return data;
  } catch (error) {
    dispatch(createAccountFailure(error));
    throw error;
  }
};

export default createAccountAction;
