import React from 'react';

import { Button, Typography } from '@material-ui/core';
import { Link as RouteLink } from 'react-router-dom';

import pathes from 'core/configs/pathesConfig';
import successImage from 'assets/images/404.svg';

const PageNotFound = () => (
  <div className="page-not-found__wrap">
    <img src={successImage} width="282" height="152" alt="404" />
    <Typography variant="h2" component="h2" className="page-not-found__title">Page not found</Typography>
    <Typography variant="body1" component="p" className="page-not-found__text">
      The Link you followed is incorrect. Please ensure that link is correct and try again.
    </Typography>
    <Button
      component={RouteLink}
      variant="contained"
      color="primary"
      className="page-not-found__btn"
      data-testid="page-not-found__btn"
      to={pathes.signin}
    >
      Sign in
    </Button>
  </div>
);

export default PageNotFound;
