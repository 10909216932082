import { HTTPService } from 'core/services';
import verifyEmail from './api.service';
import {
  verifyEmailFailure,
  verifyEmailRequest,
  verifyEmailSuccess,
} from './actionsCreators';

export const verifyEmailAction = (source, token) => async (dispatch) => {
  try {
    const cancelToken = HTTPService.getCancelToken(source);

    dispatch(verifyEmailRequest());

    const data = await verifyEmail(token, cancelToken);

    dispatch(verifyEmailSuccess(data));

    return data;
  } catch (error) {
    dispatch(verifyEmailFailure(error));
    throw error;
  }
};

export default verifyEmailAction;
