import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Typography,
} from '@material-ui/core';

import { HTTPService } from 'core/services';
import { getStringWithCapitalFirstLetter } from 'core/utilities';

import getPasswordPoliciesAction from './actions';

const PasswordPolicies = () => {
  const dispatch = useDispatch();
  const source = HTTPService.generateSource();
  const passwordPolicies = useSelector((state) => state.getPasswordPolicies.data);
  const isPasswordPoliciesLoading = useSelector((state) => state.getPasswordPolicies.isLoading);

  const getPasswordPolicy = (content) => (<Typography variant="body1" component="li" className="password-policies__row">{getStringWithCapitalFirstLetter(content)}</Typography>);

  const getPasswordPolicies = () => (passwordPolicies.length ? (
    <ul className="password-policies__list">
      {passwordPolicies.map(({ description }) => getPasswordPolicy(description))}
    </ul>
  ) : '');

  useEffect(() => {
    dispatch(getPasswordPoliciesAction(source));
  }, []);

  return !isPasswordPoliciesLoading ? getPasswordPolicies() : '';
};

export default PasswordPolicies;
