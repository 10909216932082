import { HTTPService } from 'core/services';
import sendEmail from './api.service';
import {
  sendEmailFailure,
  sendEmailRequest,
  sendEmailSuccess,
} from './actionsCreators';

export const sendEmailAction = (source, dataForSendEmail) => async (dispatch) => {
  try {
    const cancelToken = HTTPService.getCancelToken(source);

    dispatch(sendEmailRequest());

    const { data } = await sendEmail(dataForSendEmail, cancelToken);

    dispatch(sendEmailSuccess(data));

    return data;
  } catch (error) {
    dispatch(sendEmailFailure(error));
    throw error;
  }
};

export default sendEmailAction;
