import {
  GET_PASSWORD_POLICIES_FAILURE, GET_PASSWORD_POLICIES_REQUEST, GET_PASSWORD_POLICIES_SUCCESS,
} from './actionTypes';

const initialState = { error: false, data: [] };

const getPasswordPoliciesReducer = (state = initialState, { type, data, error }) => {
  switch (type) {
    case GET_PASSWORD_POLICIES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PASSWORD_POLICIES_SUCCESS:
      return {
        ...state,
        data,
        isLoading: false,
      };
    case GET_PASSWORD_POLICIES_FAILURE:
      return {
        ...state,
        error,
        isLoading: false,
      };
    default:
      return {
        ...state,
        isLoading: false,
      };
  }
};

export default getPasswordPoliciesReducer;
