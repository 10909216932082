import { HTTPService } from 'core/services';
import validateInvite from './api.service';
import {
  validateInviteFailure,
  validateInviteRequest,
  validateInviteSuccess,
} from './actionsCreators';

export const validateInviteAction = (source, key) => async (dispatch) => {
  try {
    const cancelToken = HTTPService.getCancelToken(source);

    dispatch(validateInviteRequest());

    const data = await validateInvite(key, cancelToken);

    dispatch(validateInviteSuccess(data));

    return data;
  } catch (error) {
    dispatch(validateInviteFailure(error));
    throw error;
  }
};

export default validateInviteAction;
