import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import { ThemeProvider } from '@material-ui/styles';

import theme from '@nv2/nv2-pkg-js-theme';
import styles from '@nv2/nv2-pkg-js-theme/src/components/styles/variables.module.scss';

import history from 'core/history';
import { HTTPService } from 'core/services';
import getAppVariablesAction from 'core/state/AppVariables/actions';
import BaseLayout from './features/BaseLayout';

import './App.scss';

const primaryColor = styles.brandBlueColor500;
const secondaryColor = styles.yellowColor500;

const App = () => {
  const dispatch = useDispatch();
  const source = HTTPService.generateSource();
  const apiUrl = useSelector((state) => state.appVariables.data.apiUrl);

  useEffect(async () => {
    const data = await dispatch(getAppVariablesAction(source));

    HTTPService.useAxiosInterceptors(data.apiUrl);
    HTTPService.setNotFoundError(dispatch);
  }, []);

  return apiUrl ? (
    <ThemeProvider theme={theme(primaryColor, secondaryColor)}>
      <CookiesProvider>
        <Router>
          <Route>
            <BaseLayout history={history} />
          </Route>
        </Router>
      </CookiesProvider>
    </ThemeProvider>
  ) : '';
};

export default App;
