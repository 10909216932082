import pathes from 'core/configs/pathesConfig';

const signup = {
  text: 'Don’t have an account?',
  buttonText: 'Sign up',
  routePath: pathes.signup,
};

const signin = {
  text: 'Got an account?',
  buttonText: 'Sign in',
  routePath: pathes.signin,
};

const getHeaderInfo = {
  signup: { ...signin },
  signin: { ...signup },
  verifyEmail: false,
  createPassword: { ...signin },
};

export default getHeaderInfo;
