import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from '@material-ui/core';

import successImage from 'assets/images/activation-email.svg';

const AccountActivationInfo = () => {
  const email = useSelector((state) => state.validateInvite.data.email);

  return (
    <div className="account-activation-info__wrap">
      <img src={successImage} width="124" height="124" alt="Activation email" />
      <Typography variant="h2" component="h2" className="account-activation-info__title">Almost done!</Typography>
      <Typography variant="body1" component="p" className="account-activation-info__text">
        We have sent an email with activation link to
        {' '}
        <Typography variant="body1" component="span" className="account-activation-info__email">{email}</Typography>
      </Typography>
      <Typography variant="body1" component="p" className="account-activation-info__text">
        Please, check your mailbox and follow the instructions from the email to sign in.
      </Typography>
    </div>
  );
};

export default AccountActivationInfo;
