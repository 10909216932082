import GET_NOT_FOUND_FROM_SERVER_SUCCESS from './actionTypes';

const initialState = { notFound: false };

const getNotFoundFromServerReducer = (state = initialState, { type }) => {
  switch (type) {
    case GET_NOT_FOUND_FROM_SERVER_SUCCESS:
      return {
        notFound: true,
      };
    default:
      return {
        ...state,
      };
  }
};

export default getNotFoundFromServerReducer;
