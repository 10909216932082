import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  Typography, FormControl, InputLabel, OutlinedInput, Button, CircularProgress,
} from '@material-ui/core';
import { AiOutlineLeft } from 'react-icons/ai';

import { HTTPService } from 'core/services';
import pathes from 'core/configs/pathesConfig';
import sendEmailAction from 'features/SendEmail/actions';
import { createAccountAction } from './actions';

const CreateAccount = ({ setAccountState, className }) => {
  const dispatch = useDispatch();
  const source = HTTPService.generateSource();

  const companyFromValidateInvite = useSelector((
    state,
  ) => state.validateInvite.data.organization_name);
  const inviteKey = useSelector((state) => state.validateInvite.data.key);
  const email = useSelector((state) => state.validateInvite.data.email);
  const firstName = useSelector((state) => state.verifyAccount.data.first_name);
  const lastName = useSelector((state) => state.verifyAccount.data.last_name);
  const isAccountCreationInProgress = useSelector((state) => state.createAccount.isLoading);
  const [company, setCompany] = useState(companyFromValidateInvite);

  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
  };

  const goToSuccessSignupStep = () => {
    setAccountState((state) => ({
      ...state,
      creation: false,
      activationInfo: true,
    }));
  };

  const goToVerifyAccountStep = () => {
    setAccountState((state) => ({
      ...state,
      verification: true,
      creation: false,
    }));
  };

  const createAccount = () => {
    const data = {
      key: inviteKey,
      first_name: firstName,
      last_name: lastName,
      organization_name: company,
    };

    try {
      return dispatch(createAccountAction(source, data));
    } catch (error) {
      return console.error(error);
    }
  };

  const sendEmail = () => {
    const redirectTo = `${window.origin}${pathes.createPassword}`;
    const data = {
      email,
      redirect_to: redirectTo,
    };

    try {
      return dispatch(sendEmailAction(source, data));
    } catch (error) {
      return console.error(error);
    }
  };

  const createAccountAndSendEmail = async () => {
    try {
      await createAccount();

      return sendEmail();
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  const submitCreateAccountForm = async (e) => {
    e.preventDefault();

    try {
      await createAccountAndSendEmail();

      goToSuccessSignupStep();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <section className={`create-account__wrap ${className} ${isAccountCreationInProgress ? 'readonly' : ''}`}>
      <Button className="create-account__back" onClick={goToVerifyAccountStep} data-testid="create-account__back">
        <AiOutlineLeft size="14" />
        Back
      </Button>
      <Typography variant="body1" component="span" className="create-account__text">
        {firstName}
        , welcome to the Nextgen Connected Platform!
      </Typography>
      <Typography variant="h1" component="h1" className="create-account__title">What’s your company name?</Typography>
      <form className="create-account__form" onSubmit={submitCreateAccountForm}>
        <div className="create-account__row">
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="company" className="MuiInputLabel_large">Company</InputLabel>
            <OutlinedInput
              id="company"
              value={company}
              type="text"
              onChange={handleCompanyChange}
              labelWidth={80}
              className="MuiOutlinedInput_large"
              data-testid="company"
              autoFocus={!company}
            />
          </FormControl>
        </div>
        <div className="create-account__row">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className="create-account__btn-confirm"
            disabled={!company}
            type="submit"
            data-testid="confirmButton"
          >
            {isAccountCreationInProgress ? <CircularProgress color="white" size={25} /> : 'Confirm'}
          </Button>
        </div>
      </form>
    </section>
  );
};

CreateAccount.propTypes = {
  setAccountState: PropTypes.func,
  className: PropTypes.string,
};

CreateAccount.defaultProps = {
  setAccountState: () => {},
  className: '',
};

export default CreateAccount;
